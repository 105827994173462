<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarFilterBranch"
      placeholder="Transaction"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="mt-3 mb-3">
          <InputText
            class="my-2 login-input"
            v-model="filterOption.eventName"
            textFloat="Search"
            placeholder="Event name"
            type="text"
            name="text"
            isRequired
          />
          <InputSelect
            v-model="filterOption.previewOrPublish"
            :options="optionsStatus"
            title="Status"
            class="mt-2"
            valueField="value"
            textField="text"
          />
          <!-- <div class="publish-event">
            <b-form-checkbox
              v-model="filter.previewOrPublish"
              switch
              :value="1"
              :unchecked-value="null"
            >
              Publist Event
            </b-form-checkbox>
          </div> -->
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
export default {
  name: "FilterBranch",
  props: {
    visible: {
      type: Boolean,
      require: true,
    },
    filterOption: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      filter: {},
      // options filter
      optionsStatus: [
        {
          value: null,
          text: "All",
        },
        {
          value: 0,
          text: "Preview",
        },
        {
          value: 1,
          text: "Publish",
        },
      ],
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.$refs.sidebarFilterBranch.show();
      } else {
        this.$refs.sidebarFilterBranch.hide();
      }
    },
  },
  methods: {
    searchAll() {
      this.$emit("searchAll", this.filterOption);
    },
    clearFilter() {
      this.$emit("clearFilter", this.filterOption);
    },
    clearForm() {
      this.$emit("clearForm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
